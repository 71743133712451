import { ref } from 'vue';
import { createAuth0Client } from '@auth0/auth0-spa-js';
import authConfig from "../../auth_config.json";
import { apiUrl } from '@/utils';

const auth0 = ref();
const token = ref();


export default function useAuth0() {
	return {
		async init() {
			auth0.value = await createAuth0Client({
				domain: authConfig.domain,
				clientId: authConfig.clientId,
				authorizationParams: {
					redirect_uri: `${window.location.origin}`,
					scope: "read:environment read:organization profile email",
				},
			});
		},
		login() {
			auth0.value.loginWithRedirect();
		},
		logout() {
			auth0.value.logout({
				logoutParams: {
					returnTo: window.location.origin,
				}
			});
		},
		async handleRedirect() {
			while (!auth0.value) {
				await new Promise((resolve) => setTimeout(resolve, 1000));
			}
			await auth0.value.handleRedirectCallback();
		},
		async getUser() {
			while (!auth0.value) {
				await new Promise((resolve) => setTimeout(resolve, 1000));
			}
			return await auth0.value.getUser();
		},
		async isAuthenticated() {
			while (!auth0.value) {
				await new Promise((resolve) => setTimeout(resolve, 1000));
			}
			return await auth0.value.isAuthenticated();
		},
		async getToken() {
			if (!token.value) {
				while (!auth0.value) {
					await new Promise((resolve) => setTimeout(resolve, 1000));
				}
				const scope = "read:environment read:organization profile email";
				token.value = await auth0.value.getTokenSilently({
					authorizationParams: {
						audience: `${apiUrl()}`,
						scope: scope,
					}
				});
			}
			return token.value;
		},
	}
}
