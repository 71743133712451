import { createApp } from 'vue';

import VWave from 'v-wave';
import App from './App.vue';
import router from './router/router';
import '@/assets/scss/main.scss';
import '@/assets/css/main.scss';
import useAuth0 from '@/modules/useAuth0';
import { clickOutside } from '@/utils';

const auth0 = useAuth0();

auth0.init();

createApp(App).use(router).use(VWave).directive('click-outside', clickOutside).mount('#app');
