import { createRouter, createWebHistory } from 'vue-router';
import useUser from '@/modules/useUser';
import CreateAccount from '@/views/CreateAccount/CreateAccount';
import CreateEnvironment from '@/views/CreateEnvironment/CreateEnvironment';
import ResetPassword from '@/views/ResetPassword';
import CreateOrg from '@/views/CreateOrganization';
import EnvironmentsList from '@/views/EnvironmentsList';
import MainView from '@/views/MainView';
import PageNotFound from '@/views/PageNotFound';
import ValidateEmail from '@/views/ValidateEmail';
import UnauthorizedPage from '@/views/UnauthorizedPage';
import useAuth0 from '@/modules/useAuth0';

const { getUser, userState, hasSignedUp, hasEnvironments } = useUser();
const auth0 = useAuth0();

const onAuthRequired = async (to, _from) => {
	if (await auth0.isAuthenticated()) {
		if (!userState.userLoaded) {
			await getUser();
		}
		if (!hasSignedUp.value && to.fullPath !== '/create-organization') {
			return '/create-organization';
		} else if (!hasEnvironments.value && hasSignedUp.value && to.fullPath !== '/create-environment') {
			return '/create-environment';
		}
		return;
	}
	// Check if this is a callback from auth0
	const query = to.query;
	// If we have code and state, we assume it's a redirect back from Auth0
	if (query['code'] && query['state']) {
		const { handleRedirect } = useAuth0();	
		await handleRedirect();
		return '/';
	}
	else {
		auth0.login();
		return false;
	}
};

const routes = [
	{ path: '/:pathMatch(.*)*', component: PageNotFound },
	{
		path: '/',
		redirect: '/environments'
	},
	{
		path: '/create-account',
		name: 'Create account',
		component: CreateAccount
	},
	{
		path: '/reset-password',
		name: 'ResetPassword',
		component: ResetPassword
	},
	{
		path: '/validate-email',
		name: 'ValidateEmail',
		component: ValidateEmail
	},
	{
		path: '/401',
		name: 'UnauthorizedPage',
		component: UnauthorizedPage
	},
	{
		path: '/create-organization',
		name: 'CreateOrganization',
		component: CreateOrg,
		beforeEnter: onAuthRequired
	},
	{
		path: '/',
		name: 'MainView',
		component: MainView,
		props: true,
		beforeEnter: onAuthRequired,
		children: [
			{
				path: 'create-environment',
				name: 'CreateEnvironment',
				component: CreateEnvironment
			},
			{
				path: 'environments',
				name: 'Environments',
				component: EnvironmentsList
			},
			{
				path: 'environments?:name',
				name: 'EnvironmentDetails',
				component: EnvironmentsList,
				props: true
			}
		]
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

export default router;
