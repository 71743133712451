<template>
	<div class="app-bar" data-cy="appbar">
		<help-menu />
		<div class="app-bar__user-menu">
			<svg-icon element="button" type="button" icon="user" class="app-bar__user-menu__trigger" @click.stop="isOpen = !isOpen" />
			<transition name="popup-menu">
				<div v-if="isOpen" class="app-bar__user-menu__popup" v-click-outside="closePopupMenu">
					<div class="app-bar__user-menu__area app-bar__user-menu__area--header">
						<div
							v-if="initials"
							:class="['app-bar__user-menu__initials-circle', { 'app-bar__user-menu__initials-circle--long-name': initials.length > 2 }]"
						>
							{{ initials }}
						</div>
						<h3 class="app-bar__user-menu__username font-emphasize">{{ userName ?? '' }}</h3>
						<p class="font-secondary">{{ organization.value?.organizationName ?? '' }}</p>
					</div>
					<div class="app-bar__user-menu__area app-bar__user-menu__area--mfa">
						<div class="app-bar__user-menu__mfa-toggle">
							<strong>Multi Factor Auth</strong>
							<toggle-field
								v-model="mfaToggleFieldValue"
								left-label="Off"
								left-value="off"
								right-label="On"
								right-value="on"
								size="large"
								show-labels-inline
								traffic-lights
							/>
						</div>
						<div v-if="mfaToggleFieldValue === 'on'" class="app-bar__user-menu__mfa-info">
							<p class="font-secondary">
								If you enable <i>MFA</i> for your account you will be asked to enroll with your
								authentication app (e.g. Google Authenticator) the next time you log in.
							</p>
							<p class="font-secondary">
								If you already have enrolled with your account on a previous occasion you will
								only be prompted to enter the active one time password.
							</p>
						</div>
					</div>
					<div class="app-bar__user-menu__area">
						<button type="button" @click="logout" class="app-bar__user-menu__link">
							<svg-icon icon="logout" class="app-bar__user-menu__link__icon" /> Log out
						</button>
					</div>
				</div>
			</transition>
		</div>
	</div>
</template>
<script>
import useUser from '@/modules/useUser';
import useOrganization from '@/modules/useOrganization';
import SvgIcon from '@/components/SvgIcon';
import ToggleField from "@/components/ToggleField";
import { computed, ref } from "vue";
import { watch } from "vue";
import HelpMenu from "@/components/HelpMenu";
import useAuth0 from '@/modules/useAuth0';

export default {
	components: {
		SvgIcon,
		ToggleField,
		HelpMenu
	},
	setup() {
		const auth0 = useAuth0();
		const isOpen = ref(false);

		const {
			toggleMfaEnabled,
		} = useUser();


		const mfaToggleFieldValue = ref('on');
		const userName = ref('');
		
		auth0.getUser().then(user => {
			mfaToggleFieldValue.value = user['https://avassa.io/useMfa'] ? 'on' : 'off';
			userName.value = user.name;
		});

		watch(mfaToggleFieldValue, (newVal) => {
			toggleMfaEnabled(newVal === 'on');
		});

		const closePopupMenu = () => {
			if (isOpen.value) isOpen.value = false;
		};

		const { organization } = useOrganization();

		const initials = computed(() => {
			if (!userName.value) return '';
			const nameParts = userName.value.split(' ');
			return nameParts.map(part => part.slice(0, 1)).join('');
		});

		const logout = () => {
			auth0.logout();
		};

		return {
			mfaToggleFieldValue,
			isOpen,
			closePopupMenu,
			logout,
			userName,
			organization,
			initials
		};
	}
};
</script>
<style lang="scss" scoped>
.app-bar {
	@extend %flex-parent-row;
	flex-shrink: 0;
	justify-content: right;
	align-items: center;
	position: relative;
	height: $top-menu-height;
	border-bottom: 1px solid $color-border;
	padding-right: $spacing;
	background-color: $color-background-light;
	pointer-events: auto;

	&__user-menu {
		&__trigger {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 40px;
			height: 40px;
			margin: 0 0 0 $spacing-xlarge;
			border-radius: 50%;
			background-color: $color-primary-brand;
			cursor: pointer;
			font-size: $icon-size-large;
			stroke: $color-white;
		}

		&__popup {
			transform-origin: 250px top;
			position: absolute;
			top: $top-menu-height;
			right: 0;
			z-index: $zindex-active-elements;
			width: 300px;
			background-color: $color-background-body;
			box-shadow: $box-shadow-top-menu-popup;
			pointer-events: auto;
			border-left: 1px solid $color-border;
			border-bottom: 1px solid $color-border;
			overflow: hidden;
		}

		&__area {
			&:not(:last-child) {
				border-bottom: 1px solid $color-border;
			}

			&--header {
				padding: $spacing-large $spacing;
				background-color: $color-background-light;
				text-align: center;
			}

			&--mfa {
				padding: $spacing-large $spacing;
			}
		}

		&__username {
			font-size: $font-size-standard;
			color: $color-text-secondary;
			font-weight: $font-weight-bold;
			margin: 0;
		}

		&__initials-circle {
			display: flex;
			justify-content: center;
			width: 100px;
			height: 100px;
			margin: 0 auto $spacing-small auto;
			border-radius: 50%;
			background-color: $color-primary-brand;
			color: $color-white;
			font-size: $font-size-xxxlarge;
			line-height: 100px;
			overflow: hidden;

			&--long-name {
				font-size: $font-size-xlarge;
			}
		}

		&__mfa-toggle {
			@extend %flex-parent-row;
			justify-content: space-between;
			align-items: center;

			.toggle-field {
				margin-bottom: 0;
			}
		}

		&__mfa-info {
			margin-top: $spacing;

			p:not(:last-child) {
				margin-bottom: $spacing-small;
			}
		}

		&__link {
			padding: $spacing;
			width: 100%;
			transition: background duration(standard) ease;

			&:hover {
				background-color: $color-background-hover;
			}

			&__icon {
				margin-right: $spacing-xsmall;
				vertical-align: middle;
				font-size: $icon-size-standard;
			}
		}
	}
}
</style>
