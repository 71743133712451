<template>
	<div class="name-environment">
		<p v-if="category !== 'production'">
			Try the Avassa free trial environment for 14 days.
			A trial environment will be set up with four virtual edge sites available in Control Tower when you log in.
			You can also add external edge hosts to your trial account.
		</p>

		<div class="name-environment__input-container">
			<InputField
				name="Environment name"
				style="max-width: 25em"
				:placeholder="`For example: ${category === 'production' ? 'Production' : 'Trial, Test or Pilot'}`"
				v-model="name"
				ref="nameInputElement"
				@input="() => validateName()"
				@keyup.enter="submit"
				:error="nameValidated"
				:length="20"
				data-cy="name-environment__name-input"
			/>
			<LoadingButton @click="submit" :loading="loading" data-cy="name-environment__create">Create</LoadingButton>
		</div>

		<p class="font-secondary">
			The URL to your environment will be
			<span v-if="!name">of the form </span>
			<strong class="name-environment__url-example">
				{{name.toLowerCase() || '[environment name]'}}.{{ subDomain }}.avassa.net
			</strong>
		</p>
	</div>
</template>

<script>
import InputField from '@/components/InputField';
import LoadingButton from '@/components/LoadingButton';
import useEnvironments from '@/modules/useEnvironments';
import { useRouter } from 'vue-router';
import {ref, watch, nextTick} from 'vue';
import useAuth0 from '@/modules/useAuth0';

export default {
	props: {
		category: String
	},
	components: { InputField, LoadingButton },
	setup(props) {
		const { addEnvironment, environments } = useEnvironments();
		const router = useRouter();
		const newPlatformName = ref('');
		const nameValidated = ref('');
		const loading = ref(false);
		const nameInputElement = ref(null);

		const auth0 = useAuth0();
		const subDomain = ref('');

		auth0.getUser().then(user => {
			subDomain.value = user['https://avassa.io/tenant'] ?? '';
		});

		watch(() => props.category, () => {
			nextTick(() => nameInputElement.value?.focusInput());
		}, { immediate: true });

		const validateName = submit => {
			if (submit && newPlatformName.value === '') {
				nameValidated.value = 'Name cannot be empty';
			} else if (/[^A-Za-z0-9-].*/.test(newPlatformName.value)) {
				nameValidated.value = 'Allowed characters in name are A-Z, a-z, 0-9 and -)';
			} else if (newPlatformName.value?.endsWith('-')) {
				nameValidated.value = 'Name must end with A-Z, a-z, 0-9';
			} else {
				const nameExists = environments.value.some(({ name }) => name === newPlatformName.value);
				nameValidated.value = nameExists ? 'Environment name already in use' : '';
			}
		};

		const submit = async () => {
			if (!loading.value) {
				loading.value = true;
				validateName(true);
				if (newPlatformName.value && nameValidated.value === '') {
					const res = await addEnvironment(newPlatformName.value, props.category);
					if (res.status === 204) {
						await router.push(`/environments?name=${newPlatformName.value}`);
					} else {
						const body = await res.json();
						nameValidated.value = body.errorMessages[0]?.includes('Max number of')
							? 'Max number of active environments reached!'
							: "Sorry, something didn't work as expected";
					}
				}
				loading.value = false;
			}
		};

		return {
			addPlatform: addEnvironment,
			name: newPlatformName,
			submit,
			environments,
			nameValidated,
			validateName,
			nameInputElement,
			loading,
			subDomain
		};
	}
};
</script>

<style lang="scss" scoped>
.name-environment {
	border: 1px solid $color-border;
	border-radius: $border-radius;
	padding: $spacing-xlarge;
	background-color: $color-primary-brand-50;

	:deep(.font-error) {
		color: red;
	}

	&__input-container {
		display: flex;
		margin: 0 0 $spacing 0;

		p + & {
			margin-top: $spacing;
		}

		.btn {
			display: flex;
			align-self: flex-start;
			margin-top: 26px;
			margin-left: $spacing-small;
		}
	}

	.text-btn {
		display: inline-block;
		margin-top: $spacing-large;
		white-space: nowrap;
	}

	&__url-example {
		white-space: nowrap;
	}
}
</style>
