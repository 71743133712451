import { reactive } from 'vue';
import auth0 from 'auth0-js';
import { computed, toRef, watchEffect } from 'vue';
import useEnvironments from '@/modules/useEnvironments';
import useOrganization from '@/modules/useOrganization';
import { get, post } from '@/utils';
import authConfig from "../../auth_config.json";

const databaseConnection = 'Username-Password-Authentication';

const userState = reactive({
	userLoaded: false,
	hasSignedUp: false,
	error: null,
	user: {},
	hasOrganization: false,
	isLoading: false,
	isRefreshing: undefined,
	sessionTimeout: false,
	pollingInProgress: undefined,
});

const { environments: environmentsState } = useEnvironments();
const { organization: organizationState } = useOrganization();

const hasPendingActions = environments =>
	environments.some(
		({ status }) => status === 'CREATING' || status === 'CREATED' || status === 'DELETING'
	);

export default function useUser() {
	const waitForUser = () => {
		if (userState.isLoading) {
			//We're already fetching the user, just sit tight
			return new Promise(resolve => {
				watchEffect(() => {
					if (!userState.isLoading && userState.userLoaded) {
						resolve('done');
					}
				});
			});
		}
	};

	const getUser = async () => {
		userState.isLoading = true;
		const response = await get('/cloud-platform-api/v2/users/me');
		if (response.status === 200) {
			const user = await response.json();
			const { organization, ...rest } = user;
			const { environments, ...restOrg } = organization;
			userState.user = rest;
			organizationState.value = restOrg;
			environmentsState.value = environments;
			userState.hasSignedUp = true;
		} else {
			userState.hasSignedUp = false;
		}
		userState.isLoading = false;
		userState.userLoaded = true;
		if (hasPendingActions(environmentsState.value)) {
			if (!userState.pollingInProgress) {
				userState.pollingInProgress = setInterval(getUser, 1000);
			}
		} else {
			clearInterval(userState.pollingInProgress);
			userState.pollingInProgress = undefined;
		}
	};

	const signup = (username, password, given_name, family_name, country, newsletterConsent, cb) => {
		const webAuth = new auth0.WebAuth({
			domain: authConfig.domain,
			clientID: authConfig.clientId,
			redirectUri: window.location.host
		});
		webAuth.signup(
			{
				email: username,
				password,
				connection: databaseConnection,
				name: `${given_name} ${family_name}`,
				given_name,
				family_name,
				user_metadata: {
					newsLetter: newsletterConsent.toString(),
					country
				}
			},
			cb
		);
	};

	function toggleMfaEnabled(mfaEnabled) {
		post('/cloud-platform-api/v2/users/command/set-mfa', {
			mfaEnabled
		});
	}
	return {
		getUser,
		waitForUser,
		userLoaded: computed(() => {
			return userState.userLoaded;
		}),
		hasSignedUp: computed(() => userState.hasSignedUp),
		hasEnvironments: computed(() => {
			return environmentsState.value?.length > 0;
		}),
		sessionTimeout: toRef(userState, 'sessionTimeout'),
		userState,
		signup,
		resetPassword: (email, cb = () => {}) => {
			const webAuth = new auth0.WebAuth({
				domain: authConfig.domain,
				clientID: authConfig.clientId,
				redirectUri: window.location.host
			});
			webAuth.changePassword({ email, connection: databaseConnection }, cb);
		},
		toggleMfaEnabled
	};
}
